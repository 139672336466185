import React from "react";
import { useState, useRef } from "react";
import './index.css'


function Navbar() {
    return (
        <nav>
            <div className="logo">
                <img src="/logo.png" alt="logo" />
            </div>
            <div className="middle-container">
                <a className="btn noborder" href="/">FAQs</a>
                <a className="btn noborder" href="/">Blogs</a>
            </div>
            <div className="right-container">
                <a className="btn" href="/">Tournaments</a>
                <a className="btn adv" href="/">Register Now</a>
                <a className="btn" href="/">Login</a>
                <div className="hamburger" onClick={() => document.querySelector(".mobile-menu").classList.toggle("active")}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div> 
            </div>
            <div className="mobile-menu">
                <a href="/">FAQs</a>
                <a href="/">Blogs</a>
                <a href="/">Tournaments</a>
                <a href="/">Login</a>
            </div>
        </nav>
    );
}

function FAQ( {index, question, answer, currindex, setFaqindex} ) {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    if (index !== currindex && open) {
        setOpen(false);
    }
    if (ref.current) {
        console.log(ref.current.scrollHeight) 
    }
    return (
        <div className="faq">
            <button onClick={(e) => {setOpen(!open); setFaqindex(index);}} >
                <div className="faq-text">
                    <p className="question">
                        <div>{question}</div></p>
                    <p ref={ref} className={"answer" + (open ? " open" : "") }>{answer}</p>
                </div>
            </button>
        </div>
    )
}

function Testimonial( {data} ) {
    

    return (
        <div className="testimonials">
            {
                data.map((data, index) => {
                    return (
                        <div className={"testimonial"} key={data.author}>
                            <div className="author">
                                <img src={data.img} alt="author" />
                                <p className="author-name">{data.author}<br/><span>{data.pref}</span></p>
                            </div>
                            <div className="quote">
                                <p>{data.quote}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

function SlideShow( {imgs} ) {

    const [currentSlide, setCurrentSlide] = useState(0);


    return (
        <div className="slideshow-container">
            <div className="slideshow">
                {
                    imgs.map((img, index) => {
                        if (typeof img === "string") {
                            return (
                                <div className={"slide slider__active-"+(currentSlide+1)} key={index} >
                                    <img src={img} alt="slide" />
                                </div>
                            )
                        } else {
                            return (
                                <div className={"testimonial slider__active-" +(currentSlide+1)} key={img.author}>
                                    <div className="author">
                                        <img src={img.img} alt="author" />
                                        <p className="author-name">{img.author}<br/><span>{img.pref}</span></p>
                                    </div>
                                    <div className="quote">
                                        <p>{img.quote}</p>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div className="links">
                {
                    imgs.map((img, index) => {
                        return (
                            <div className={"slide-link " + (currentSlide === index ? "active" : "")} key={index} onClick={() => setCurrentSlide(index)}></div>
                        )
                    })
                }
            </div>
        </div>
            
    )
}


export default function Home() {
    const [faqindex, setFaqindex] = useState(0);

    const testimonial_data = [
        {
            img: "/author1.png",
            author: "Alex Johnson",
            pref: "Avid Gamer",
            quote: "Since I started using this platform, my gaming experience has never been the same. The ease of connecting with other players and the security of transactions have truly set a new standard for online betting."
        },
        {
            img: "/author2.png",
            author: "David Kim",
            pref: "Professional Chess Player",
            quote: "Joining tournaments has never been easier! The platform’s structure for setting up and participating in large-scale gaming events is flawless. The automated result tracking and fair play checks ensure every match is just and enjoyable."
        },
        {
            img: "/author3.png",
            author: "Sarah Miller",
            pref: "Competitive Gamer",
            quote: "The integration with PayPal made it so easy to deposit and withdraw winnings without any hassle. The customer support team is also incredibly responsive and helpful, making sure any issues are resolved quickly. It’s the best betting platform I’ve used!"
        },
    ]
    
    return (
        <div>
            <div className="content main">
                <Navbar/>
                <div className="headings">
                    
                    <div className="logocontent">
                        <h1>Join the Ultimate Online Gaming Betting Experience</h1>
                        <p>Bet, play, and win big on your favourite chess and gaming battles</p>
                        <a className="btn" href="/">Sign up Now</a>
                        <div className="seals">
                            <div className="seal">
                                <img src="/sealcheck.svg" alt="sealcheck" />
                                <p>Supports all competitive games</p>
                            </div>
                            <div className="seal">
                                <img src="/sealcheck.svg" alt="sealcheck" />
                                <p>Direct PayPal integration</p>
                            </div>
                            <div className="seal">
                                <img src="/sealcheck.svg" alt="sealcheck" />
                                <p>Automated results tracking for supported games</p>
                            </div>
                        </div>
                    </div>
                    <div className="logomaincontainer">
                        <img className="logomain" src="/mainlogo.png" alt="mainlogo"/>
                    </div>
                </div>
            </div>
            {
                window.innerWidth > 750 ? <Testimonial data={ testimonial_data} /> : <SlideShow imgs={testimonial_data}></SlideShow>
            }
            <div className="about-container flexbox">
                <SlideShow imgs={["/pubg.png", "/chess.png", "/valorant.png"]} />
                <div className="about-text">
                    <h2>About the Platform</h2>
                    <p>Dive into a seamless betting experience with our dedicated Discord bot and interactive website, designed for gaming enthusiasts and competitive spirits worldwide.</p>
                    <div className="about-details">
                        <div className="about-detail flexbox">
                            <img src="/about/bet.svg" alt="sealcheck" />
                            <p>Streamlined Betting Interface</p>
                        </div>
                        <div className="about-detail flexbox">
                            <img src="/about/comp.svg" alt="sealcheck" />
                            <p>Competitive Gaming Hub</p>
                        </div>
                        <div className="about-detail flexbox">
                            <img src="/about/transactions.svg" alt="sealcheck" />
                            <p>Fast and Secure Transactions</p>
                        </div>
                    </div>
                    <a className="btn adv" href="/">FAQs</a>
                </div>
            </div>
            <div className="gamesupport-container flexbox">
                <div className="gamesupport-text">
                    <h2>What games do we support?</h2>
                    <p>We support all competitive games but some are automatically tracked some need to report result</p>
                    <a className="btn" href="/">Learn More</a>
                </div>
                <SlideShow imgs={["/apex.png", "/valorant.png", "/chess.png", "/pubg.png", "/lichess.png", "/dota2.png", "/destiny2.png", "/lor.png", "/smite.png"]} />
            </div>
            <div className="working-container">
                <h2>How it works</h2>
                <div className="working-details">
                    <div className="working-detail flexbox">
                        <img src="/working/1.svg" alt="sealcheck" />
                        <div className="working-text">
                            <h3>Connect your account</h3>
                            <p>Integrate directly with top gaming platforms like lichess.org and chess.com.<br/>Chess.com Lichess.org Destiny 2 PUBG Smite  Dota 2 Valorant  LoL TFT Legends of runeterra Apex Legends</p>
                        </div>
                    </div>
                    <div className="working-detail flexbox">
                        <img src="/working/2.svg" alt="sealcheck" />
                        <div className="working-text">
                            <h3>Place your bets</h3>
                            <p>Engage in thrilling one-on-one matches or join knockout tournaments with up to 128 players.</p>
                            <p>Deposit safely with PayPal to start your betting journey.</p>
                        </div>
                    </div>
                    <div className="working-detail flexbox">
                        <img src="/working/3.svg" alt="sealcheck" />
                        <div className="working-text">
                            <h3>Win and Withdraw</h3>
                            <p>Claim 90% of the total betting pool when you conquer your opponents.</p>
                            <p>Withdraw your winnings directly through a user-friendly interface.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features">
                <h2>Features</h2>
                <div className="features-details">
                    <div className="feature flexbox">
                        <img src="/features/1.svg" alt="sealcheck" />
                        <div className="feature-text">
                            <h3>Real-Time Integration</h3>
                            <ul>
                                <li>Automatic fetching of game results from popular games including Overwatch, Fortnite, CSGO, and more.</li>
                                <li>Players can submit screenshots for games outside our automatic result system.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature flexbox">
                        <img src="/features/2.svg" alt="sealcheck" />
                        <div className="feature-text">
                            <h3>Security and Trust</h3>
                            <ul>
                                <li>Ensured safety with PayPal for all deposits and withdrawals.</li>
                                <li>Our system is designed with integrity checks and balance verifications to guarantee a fair betting environment.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature flexbox">
                        <img src="/features/3.svg" alt="sealcheck" />
                        <div className="feature-text">
                            <h3>Join the Community</h3>
                            <ul>
                                <li>Connect with fellow gamers, discuss strategies, and share your victories in our dedicated Discord server.</li>
                                <li>Round-the-clock support to assist with any queries or issues.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faqs">
                <h2>FAQs</h2>
                <FAQ index={0} currindex={faqindex} setFaqindex={setFaqindex} question="What is duelbet.me?" answer="duelbet.me is the world’s most popular platform to win money playing video games. Players have won over $2,000,000 playing on Players’ Lounge. We connect you with opponents with the tap of a button. You can wager against other players in head-to-head matches, play in free tournaments to earn money, or bet on yourself in cash entry tournaments for big prizes. Ready to win money playing your favorite video games?"/>
                <FAQ index={1} currindex={faqindex} setFaqindex={setFaqindex} question="How do I sign up?" answer="Placeholder"/>
                <FAQ index={2} currindex={faqindex} setFaqindex={setFaqindex} question="Does it cost anything to sign up for duelbet.me?" answer="Placeholder"/>
                <FAQ index={3} currindex={faqindex} setFaqindex={setFaqindex} question="How old must I be to use duelbet.me?" answer="Placeholder"/>
                <FAQ index={4} currindex={faqindex} setFaqindex={setFaqindex} question="Is there a duelbet.me?" answer="Placeholder"/>
                <FAQ index={5} currindex={faqindex} setFaqindex={setFaqindex} question="Is duelbet.me legal?" answer="Placeholder"/>
            </div>
            <div className="getstarted">
                <h2>Get Started</h2>
                <p>Ready to place your bet? Join now and be part of the action!</p>
                <a href="/register"><button className="btn">Sign up Now</button></a>
            </div>
            <div className="footer">
                <div className="footer-details">
                    <div className="footer-logo">
                        <img src="/logo.png" alt="logo" />
                    </div>
                    <div className="footer-text">
                        <div className="footer-cat">
                            <h4>Quick Links</h4>
                            <a href="/">Blog</a>
                            <a href="/">Refer Friends, Get Cash</a>
                        </div>
                        <div className="footer-cat">
                            <h4>Contact Information</h4>
                            <a href="/">Customer Service</a>
                            <a href="/">FAQs</a>
                        </div>
                        <div className="footer-cat">
                            <h4>Customer Support</h4>
                            <a href="/">Terms of Service</a>
                            <a href="/">Betting policies</a>
                            <a href="/">Disputes</a>
                            <a href="/">Privacy Policy </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}